<template>
    <v-select
      :label="selectLabel"
      v-model="mainPreset"
      :items="presets"
      item-text="preset_name"
      item-value="id"
      :disabled="presets.length <= 1"
      outlined
      dense
      class="mt-4"
    ></v-select>
</template>

<script>
import { EventBus } from '@/utils/event-bus';
export default {
    name: 'EnterNodeMainPreset',
    data(){
        return{
            mainPreset: null
        }
    },
    props: {
        presets: {
            type: Array
        }
    },
    created(){
        this.mainPreset = this.$store.getters["processes/enterNode"].presetId
    },
    watch: {
        mainPreset(newPresetId){
            EventBus.$emit('updatePresetIdOfEnterNode', newPresetId)
        }
    },
    computed: {
        selectLabel(){
            return this.presets.length < 1 ? this.$t('node.intoPreset.withoutPreset') : this.$t('node.intoPreset.defaultPreset')
        }
    }
}
</script>

<style>

</style>
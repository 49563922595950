export default {
  data () {
    return {
      sidebarItems: [
        // {
        //   name: 'main',
        //   link: '/',
        //   title: 'Главная',
        //   icon: 'mdi-home-outline',
        //   color: '#596CFF'
        // },
        {
          name: 'business-process',
          link: '/business-process',
          title: 'processes',
          icon: 'mdi-cellphone-cog',
          color: '#FD7E14'
        },
        // {
        //   name: 'widgets',
        //   link: '/widgets',
        //   title: 'widgets',
        //   icon: 'mdi-widgets-outline',
        //   color: '#2dce89'
        // },
        {
          name: 'app-translate',
          link: '/app-translate',
          title: 'multiLang',
          icon: 'mdi-translate',
          color: '#f5365c'
        },
        {
          name: 'main-props',
          link: '/main-props',
          title: 'settings',
          icon: 'mdi-cog-outline'
        }
      ],
      drawer: null,
      // miniSidebar: null
    }
  }
}

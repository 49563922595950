export function scrollToElement(el) {
  if (el) {
    el.scrollIntoView({behavior: 'smooth'});
  }
}

export function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false;
  }
  return true;
}

// refactor fields to put in wyswig with right tags
export function refactorFieldToView(str) {
  if (typeof str === 'string' && str.includes("<span")) {
    //regexp for replace span tags
    const emRegExEmpty = /span class='mod-text-italic'>/gi;
    const emRegEx = /span class='mod-text-italic'/gi;
    const strongRegExEmpty = /span class='mod-text-weight-bold'>/gi;
    const strongRegEx = /span class='mod-text-weight-bold'/gi;
    const aRegEx = /span class='url'/gi;
    const uRegExEmpty = /span class='mod-text-underline'>/gi;
    const uRegEx = /span class='mod-text-underline'/gi;
    // default regex
    const defaultRegEx = /span/i;

    //regex close span
    const closeSpanRegEx = /\/span>/i;

    // do split by open tag and make stack of close tags
    let tagsStack = [];
    let workArr = str.split("<");

    // replace span tags for wyswig tags
    let resultArr = workArr.map(elem => {
      if (elem.includes("span class='mod-text-italic'>")) {
        elem = elem.replace(emRegExEmpty, '<em>');
        tagsStack.push("</em>");
      } else if (elem.includes("span class='mod-text-italic'")) {
        elem = elem.replace(emRegEx, '<em');
        tagsStack.push("</em>");
      } else if (elem.includes('span class=\'mod-text-weight-bold\'>')) {
        elem = elem.replace(strongRegExEmpty, '<strong>');
        tagsStack.push("</strong>");
      } else if (elem.includes('span class=\'mod-text-weight-bold\'')) {
        elem = elem.replace(strongRegEx, '<strong');
        tagsStack.push("</strong>");
      } else if (elem.includes('span class=\'url\'')) {
        elem = elem.replace(aRegEx,'<a');
        tagsStack.push("</a>");
      } else if (elem.includes('span class=\'mod-text-underline\'>')) {
        elem = elem.replace(uRegExEmpty, '<u>');
        tagsStack.push("</u>");
      } else if (elem.includes('span class=\'mod-text-underline\'')) {
        elem = elem.replace(uRegEx, '<u');
        tagsStack.push("</u>");
      } else if (elem.includes('span') && !elem.includes("/span")) {
        elem = elem.replace(defaultRegEx, '<p');
        tagsStack.push("</p>");
      }

      // replace close span tags for wyswig tags from stack
      if (elem.includes("/span>")) {
        let closeTag = tagsStack.pop();
        elem = elem.replace(closeSpanRegEx, closeTag);
      }
      return elem;
    })
    return resultArr.join('');
  }
  return str;
}

/** generate formData for api methods when work with files / images */

export function generateFormData(data) {
  let formData = new FormData();
  if (data.length > 1 || data[0].type.includes("image")) {
    data.forEach((img) => {
      formData.append(img.name, img);
    })
  } else {
    formData.append('file', data[0]);
  }
  return formData;
}
import Vue from "vue";
import VueRouter from "vue-router";

import LoginPage from "@/pages/LoginPage";
import MainProps from "@/pages/MainProps";
import BusinessProcess from "@/pages/BusinessProcess";
// import WidgetsPage from "@/pages/WidgetsPage";
import TranshUx from "@/pages/TranshUx";
import HomeScreenUx from "@/pages/HomeScreenUx";
import HistoryUx from "@/pages/HistoryUx";

import AppTranslate from "@/pages/AppTranslate";
import ProfileUx from "@/pages/ProfileUx.vue";
import PaymentUx from "@/pages/PaymentUx.vue";

import MainWidget from "@/components/UI/widgets/MainWidget.vue"
import NodeRedactorFrame from "@/pages/NodeRedactorFrame.vue";

Vue.use(VueRouter);

const routes = [
  {
    name: "main-props",
    path: "/main-props",
    component: MainProps,
  },
  {
    name: "business-process",
    path: "/business-process",
    component: BusinessProcess,
  },
  {
    name: "app-translate",
    path: "/app-translate",
    component: AppTranslate,
  },
  {
    name: "login",
    path: "/login",
    component: LoginPage,
  },
  {
    name: "transhux",
    path: "/transhux",
    component: TranshUx
  },
  {
    name: "profileux",
    path: "/profileux",
    component: ProfileUx
  },
  {
    name: "homescreenux",
    path: "/homescreenux",
    component: HomeScreenUx
  },
  {
    name: "historyux",
    path: "/historyux",
    component: HistoryUx
  },
  {
    name: "paymentux",
    path: "/paymentux",
    component: PaymentUx
  },
  {
    name: "widget",
    path: "/widget-ux",
    component: MainWidget
  },
  // {
  //   name: 'widgets',
  //   path: "/widgets",
  //   component: WidgetsPage
  // },
  {
    name: "nodeRedactorFrame",
    path: "/node-redactor-frame",
    component: NodeRedactorFrame,
  },
  {
    path: "*",
    redirect: "/business-process",
  },
];

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/node-redactor-frame"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("accessToken");

  if (authRequired && !loggedIn) {
    return next("/login");
  } else if (loggedIn && publicPages.includes(to.path)) {
    return next("/");
  }

  next();
});

export default router;
